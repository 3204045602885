//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionButtons from '@/components/Profile/Settings/ActionButtons'
export default {
    props: ['dataset'],
    components: {
        ActionButtons
    },
    data() {
        return {
            share_mobile_number: this.dataset.share_mobile_number,
            share_email_address: this.dataset.share_email_address,
            mobile_number: this.dataset.mobile_number
        }
    },
    computed: {

        // The script to determine whether to unlock the save button.
        enableSave() {
            if (this.share_mobile_number != this.dataset.share_mobile_number) {
                return false;
            }
            if (this.share_email_address != this.dataset.share_email_address) {
                return false;
            }
            if (this.mobile_number != this.dataset.mobile_number) {
                return false;
            }
            
            return true;
        }
    },
    methods: {
        reset() {
            this.share_mobile_number = this.dataset.share_mobile_number,
            this.share_email_address = this.dataset.share_email_address,
            this.mobile_number = this.dataset.mobile_number
        }
    }
}
